import React from "react";
import Content from "../Content";
import { kebabCase } from "lodash";
import { Link } from "gatsby";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { Breadcrumb } from 'gatsby-plugin-breadcrumb';

const ArticleTemplate = ({
  content,
  contentComponent,
  cover,
  meta_title,
  meta_desc,
  tags,
  title,
  
}) => {
  const PostContent = contentComponent || Content;
  return (
    <div>
      <h1 className="title is-size-2 has-text-weight-bold is-bold-light">
        {title}
      </h1>
   
      {documentToReactComponents(content)}

      <div style={{ marginTop: `4rem` }}>
        <h4>Tags</h4>
        <ul className="taglist">
          {tags && tags.length
            ? tags.map((tag) => (
                <li key={tag.id + `tag`}>
                  <Link to={`/tags/${kebabCase(tag.name)}/`}>{tag.name}</Link>
                </li>
              ))
            : null}
        </ul>
      </div>
      <hr />
    </div>
  );
};

export default ArticleTemplate;
