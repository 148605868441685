import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { HTMLContent } from "../components/Content";
import ArticleTemplate from "../components/ArticleTemplate";
import SE0 from "../components/SEO";
import Share from "../components/Share";
import Disqus from "../components/Disqus";
import Layout from "../components/Layout";
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'

const ArticlePage = ({ pageContext }) => {
  const post = pageContext;
  return (
    <Layout>

      <section className="section">

        <SE0
          title={post.title}
          meta_title={post.metaTitle}
          meta_desc={post.metaDescription}
          cover={post.image}
          slug={post.slug}
          date={post.createdAt}
          directory={'/blog/'}
          contentType={'article'}
        />
        <div className="container content">
        

          <div className="columns">
            <div className="column is-10 is-offset-1">
              <ArticleTemplate
                content={post.content.json}
                contentComponent={HTMLContent}
                cover={post.slug}
                meta_title={post.metaTitle}
                meta_desc={post.metaDescription}
                tags={post.tags}
                title={post.title}
              />
              <Share
                title={post.title}
                slug={post.slug}
                excerpt={post.metaDescription}
              />
              <hr />
              <Disqus title={post.title} slug={post.slug} />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

ArticlePage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default ArticlePage;
